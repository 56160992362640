"use client";

import * as React from "react";

// @components
import Link from "next/link";
import { ChartCard } from "@/components";
import { Line, YAxis, LineChart, ResponsiveContainer } from "recharts";

// @icons
import { UserGroupIcon } from "@heroicons/react/24/outline";

// @hooks
import { useAdoptionSinceLaunch } from "@/hooks/use-adoption";

// @utils
import { yAxisConfig } from "@/utils";

export function Adoption() {
  const { data, isLoading } = useAdoptionSinceLaunch();
  const fields = React.useMemo(() => data?.chart?.fields, [data]);

  const lines = React.useMemo(() => {
    if (fields) {
      return Object.keys(fields)?.map((el) => {
        return {
          dot: false,
          dataKey: el,
          strokeWidth: 2,
          name: fields[el].title,
          stroke: fields[el].color,
          isAnimationActive: false,
        };
      });
    }

    return [];
  }, [fields]);

  return (
    <Link href="/explore/adoption" className="col-span-1 lg:col-span-2">
      <ChartCard
        title="Adoption"
        icon={UserGroupIcon}
        isLoading={isLoading}
        description="Analyze HEX user adoption data"
      >
        <ResponsiveContainer width="100%" height="100%" className="scale-95">
          <LineChart data={data?.data}>
            <YAxis {...yAxisConfig()} />
            {lines.map((props) => (
              <Line key={props.dataKey} {...props} />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </ChartCard>
    </Link>
  );
}
