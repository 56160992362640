// @hooks
import useSWR from "swr";

// @utils
import { fetcher } from "@/utils";

const endpoint = `${process.env.NEXT_PUBLIC_API_URL_V1}/adoption`;

export function useAdoptionSinceLaunch(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch ? `${endpoint}/since-launch?variation=${variation}` : null,
    fetcher,
  );
}

export function useNewHolders(shouldFetch: boolean = true, variation?: string) {
  return useSWR(
    shouldFetch ? `${endpoint}/new-holders?variation=${variation}` : null,
    fetcher,
  );
}

export function useNewStakers(shouldFetch: boolean = true, variation?: string) {
  return useSWR(
    shouldFetch ? `${endpoint}/new-stakers?variation=${variation}` : null,
    fetcher,
  );
}

export function useHexHolders(shouldFetch: boolean = true, variation?: string) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/hex-holders-by-amount?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useHexHoldersByUsd(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/hex-holders-by-usd-amount?variation=${variation}`
      : null,
    fetcher,
  );
}
