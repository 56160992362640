// @hooks
import useSWR from "swr";

// @utils
import { fetcher } from "@/utils";

const endpoint = `${process.env.NEXT_PUBLIC_API_URL_V1}/shares`;

export function useShareRate(shouldFetch: boolean = true, variation?: string) {
  return useSWR(
    shouldFetch ? `${endpoint}/share-rate?variation=${variation}` : null,
    fetcher,
  );
}

export function useTShareUSDPrice(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch ? `${endpoint}/t-share-price-usd?variation=${variation}` : null,
    fetcher,
  );
}

export function useTShareValuations(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/t-share-valuations?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTShareDailyPayout(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/t-share-daily-payout?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTotalTShares(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch ? `${endpoint}/total-t-shares?variation=${variation}` : null,
    fetcher,
  );
}

export function useStakingParticipation(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/staking-participation-and-apy?variation=${variation}`
      : null,
    fetcher,
  );
}
