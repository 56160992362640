const TOKEN_PAIRS = ["#2775ca", "#627eea", "#b70df2", "#ff652c"];
const DEX = ["#ffffff", "#ff007a", "#01e673"];
const BID_AND_ASK = ["#01e673", "#ff000c"];

export const LIQUIDITY_CHARTS = {
  TOKEN_PAIRS,
  BID_AND_ASK,
  DEX,
};

export default LIQUIDITY_CHARTS;
