// @hooks
import useSWR from "swr";

// @utils
import { fetcher } from "@/utils";

const endpoint = `${process.env.NEXT_PUBLIC_API_URL_V1}/inflation`;

export function useStockToFlow(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch ? `${endpoint}/stock-to-flow?variation=${variation}` : null,
    fetcher,
  );
}

export function useSupply(shouldFetch: boolean = true, variation?: string) {
  return useSWR(
    shouldFetch ? `${endpoint}/supply?variation=${variation}` : null,
    fetcher,
  );
}
