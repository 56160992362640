import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/adoption.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/inflation.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/liquidity.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/roi.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/shares.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/social.tsx");
