// @hooks
import useSWR from "swr";

// @utils
import { fetcher } from "@/utils";

const endpoint = `${process.env.NEXT_PUBLIC_API_URL_V1}/liquidity`;

export function useTokenPairs(shouldFetch: boolean = true) {
  return useSWR(shouldFetch ? `${endpoint}/token-pairs` : null, fetcher, {
    refreshInterval: 5000,
  });
}

export function useDex(shouldFetch: boolean = true) {
  return useSWR(shouldFetch ? `${endpoint}/dex` : null, fetcher, {
    refreshInterval: 5000,
  });
}

export function useBidAsk(shouldFetch: boolean = true) {
  return useSWR(shouldFetch ? `${endpoint}/bid-ask` : null, fetcher, {
    refreshInterval: 5000,
  });
}

export function useTableData(shouldFetch: boolean = true) {
  return useSWR(shouldFetch ? `${endpoint}/table-data` : null, fetcher, {
    refreshInterval: 5000,
  });
}

export function useRawData(shouldFetch: boolean = true) {
  return useSWR(shouldFetch ? `${endpoint}/raw-lp` : null, fetcher, {
    refreshInterval: 5000,
  });
}
