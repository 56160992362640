// @hooks
import useSWR from "swr";

// @utils
import { fetcher } from "@/utils";

const endpoint = `${process.env.NEXT_PUBLIC_API_URL_V1}/roi`;

export function useRoiFromLaunch(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch ? `${endpoint}/from-launch?variation=${variation}` : null,
    fetcher,
  );
}

export function useRoiSinceHexLaunch(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch ? `${endpoint}/from-hex-launch?variation=${variation}` : null,
    fetcher,
  );
}

export function useQuarterlyRoi(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch ? `${endpoint}/quarterly?variation=${variation}` : null,
    fetcher,
  );
}

export function useYearlyRoi(shouldFetch: boolean = true, variation?: string) {
  return useSWR(
    shouldFetch ? `${endpoint}/yearly?variation=${variation}` : null,
    fetcher,
  );
}

export function useMonthlyRoi(shouldFetch: boolean = true, variation?: string) {
  return useSWR(
    shouldFetch ? `${endpoint}/monthly?variation=${variation}` : null,
    fetcher,
  );
}
