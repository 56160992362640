// @hooks
import useSWR from "swr";

// @utils
import { fetcher } from "@/utils";

const endpoint = `${process.env.NEXT_PUBLIC_API_URL_V1}/social`;

export function useYoutubeSubscribers(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/youtube-subscribers?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useYoutubeViews(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch ? `${endpoint}/youtube-views?variation=${variation}` : null,
    fetcher,
  );
}

export function useYoutubeSubscribersChange(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/youtube-subscribers-change?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useYoutubeViewsChange(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/youtube-views-change?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTwitterInfluencerFollowers(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/twitter-influencer-followers?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTwitterInfluencerTweets(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/twitter-influencer-tweets?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTwitterProjectFollowers(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/twitter-project-followers?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTwitterProjectTweets(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/twitter-project-tweets?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTwitterInfluencerFollowersChange(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/twitter-influencer-followers-change?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTwitterInfluencerTweetsChange(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/twitter-influencer-tweets-change?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTwitterProjectFollowersChange(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/twitter-project-followers-change?variation=${variation}`
      : null,
    fetcher,
  );
}

export function useTwitterProjectTweetsChange(
  shouldFetch: boolean = true,
  variation?: string,
) {
  return useSWR(
    shouldFetch
      ? `${endpoint}/twitter-project-tweets-change?variation=${variation}`
      : null,
    fetcher,
  );
}
