"use client";

import * as React from "react";

// @components
import Link from "next/link";
import { ChartCard } from "@/components";
import { Pie, Cell, PieChart, ResponsiveContainer } from "recharts";

// @icons
import { ChartPieIcon } from "@heroicons/react/24/outline";

// @hooks
import { useTokenPairs } from "@/hooks/use-liquidity";

// @utils
import { pieChartOuter } from "@/utils";

// @constants
import { LIQUIDITY_CHARTS } from "@/constants";

export function Liquidity() {
  const { data, isLoading } = useTokenPairs();
  const [activeTokenPairs, setActiveTokenPairs] = React.useState(0);

  function onTokenParisPieEnter(_: any, index: number) {
    setActiveTokenPairs(index);
  }

  return (
    <Link href="/liquidity" className="col-span-1 lg:col-span-2">
      <ChartCard
        title="Liquidity"
        icon={ChartPieIcon}
        isLoading={isLoading}
        description="Visualize the liquidity of HEX token pairs across various DEX's & chains"
      >
        <ResponsiveContainer width="100%" height="100%" className="scale-95">
          <PieChart>
            <Pie
              cx="50%"
              cy="50%"
              data={data}
              innerRadius={80}
              outerRadius={100}
              dataKey="liquidity"
              stroke="transparent"
              activeShape={pieChartOuter}
              activeIndex={activeTokenPairs}
              onMouseEnter={onTokenParisPieEnter}
            >
              {data?.map((_: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    LIQUIDITY_CHARTS.TOKEN_PAIRS[
                      index % LIQUIDITY_CHARTS.TOKEN_PAIRS.length
                    ]
                  }
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartCard>
    </Link>
  );
}
